import React, { Component, useReducer } from "react";
import { createBrowserHistory } from 'history';

import OAuthService from '../Services/OAuthService';

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import StockNotifications from "../StockNotifications/StockNotifications";
import Orders from "../Orders/Orders";

class Dashboard extends Component {
    state = {
        worker: false,
        stocker: false,
    }

    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {
        this.checkUser();
    }

    checkUser = () => {
        let user = JSON.parse(localStorage.getItem("user"));
        if(user){
            var worker = false, stocker = false;
            if(user.roles){
                user.roles.forEach(role => {
                    if(role == "stocker") {
                        stocker = true;
                    } else if(role == "worker"){
                        worker = true
                    }
                });
            }

            if(stocker){
                this.setState({
                    worker: false,
                    stocker: true,
                })
            }else if(worker){
                this.setState({
                    worker: true,
                    stocker: false,
                })
            }else{
                const history = createBrowserHistory();
                history.push({ pathname: '/restricted' })
                window.location.reload(false);
            }
        }else{
            this.getUser();
        }
    }

    getUser = () => {
        this.os.getUserInfo().then((result) => {
            localStorage.setItem('user', JSON.stringify(result));
            this.checkUser();

        }).catch((error) => {
            console.log(error);
            localStorage.removeItem('user');
        })
    }

    render() {
        return (
            <div>
                <Header />
                {this.state.stocker && <StockNotifications />}
                {this.state.worker && <Orders />}
                <Footer />
            </div>
        )
    }
}
export default Dashboard;
