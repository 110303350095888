import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/auth";


import {
    Row, Col,
    Container
} from 'reactstrap';

const Header = props => {
    const { user, postLogout } = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    function logout(){
        postLogout();
    }

    return(
        <header className='top'>
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col>
                        <h1 className="header-title">
                            <a href="/">Tayda Stock Notifications</a>
                            <span className="tagline">
                                &nbsp;&nbsp;&nbsp;&nbsp;By <a href="https://www.taydaelectronics.com/hardware/enclosures.html" target="_blank" rel="noopener noreferrer" title="Tayda Electronics" >Tayda Electronics&reg;</a>
                            </span>
                        </h1>
                    </Col>
                    <Col md="auto" className={user != null ? "d-flex align-items-center" : "d-none"}>
                        <div>
                            <Dropdown className="btn btn-secondary p-0" isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret >
                                    <span style={{fontSize: '0.8em'}}>
                                        {user != null ? user["email"] : ""}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/dashboard">Dashboard</DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem onClick={logout}>Logout</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};


export default Header;