import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import StockNotificationsService from "../Services/StockNotificationsService";

import StockNotificationsPagination from "./StockNotificationsPagination";

import {
    Row, Col,
    Container,
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, FormText
} from 'reactstrap';

class StockNotifications extends Component {
    state = {
        showModal: false,
        refresh: false,
        stock_notification: null
    }

    constructor(props){
        super(props);
        this.stock_notifications_service = new StockNotificationsService();
    }

    componentDidMount = () => {

    }

    showStockNotication = (new_stock_notification) => {
        if (!new_stock_notification.stocked_at) {
            this.setState({
                showModal: true,
                stock_notification: new_stock_notification
            })
            if (!new_stock_notification.started_stocking_at) {
                this.updateStockNotification('start_stocking', new_stock_notification);
            } else if (new_stock_notification.offset_started_at) {
                this.updateStockNotification('unpause', new_stock_notification);
            }
        }
    }

    pauseStockNotication = async () => {
        let stock_notification = this.state.stock_notification;
        let stock_notification_rsp = await this.updateStockNotification("pause", stock_notification);

        // console.log(stock_notification_rsp);
        // if(stock_notification_rsp.error){
        //     toast.error("Something went wrong. Please try again.");
        //     return;
        // }

        let new_stock_notification = JSON.parse(JSON.stringify(stock_notification));
        // toast.success('Order  successfully updated.');
        this.setState({
            stock_notification: new_stock_notification
        })

        this.toggleModal();

    }

    updateStockNotification = (type, stock_notification) => {
        console.log("OLD: ", stock_notification);
        var body = {};

        switch(type){
            case "pending":
                body = {
                    stock_notification: {
                        offset_started_at: moment().format('YYYY-MM-DD hh:mm:ss'),
                        stock_status: "pending"
                    }
                }
                break;
            case "ready":
                body = {
                    stock_notification: {
                        offset_ended_at: moment().format('YYYY-MM-DD hh:mm:ss'),
                        stock_status: "needs_restock"
                    }
                }
                break;
            case "start_stocking":
                body = {
                    stock_notification: { started_stocking_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "pause":
                body = {
                    stock_notification: { offset_started_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "unpause":
                body = {
                    stock_notification: { offset_ended_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "restocked":
                if (!stock_notification.started_stocking_at) {
                    body = {
                        stock_notification: { started_stocking_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                    }
                }else if(stock_notification.started_stocking_at && stock_notification.stock_status != 'pending'){
                    body = {
                        stock_notification: { stocked_at: moment().format('YYYY-MM-DD hh:mm:ss'), quantity_stocked: stock_notification.quantity_stocked }
                    }
                }
                break;
        }

        this.stock_notifications_service.updateStockNotification(stock_notification.id, body).then((result) => {
            // console.log("NEW: ", result);
            if (type === "restocked") {
                toast.success('Stock notification successfully updated.');
                this.setState({
                    showModal: false,
                    refresh: true
                })
            }
            this.setState({ stock_notification: result})
            return result;
        }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong. Please try again.");
        });
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    resetRefresh = (new_refresh) => {
        this.setState({
            refresh: new_refresh
        })
    }

    setQuantity = (e) => {
        let stock_notification = this.state.stock_notification;
        stock_notification.quantity_stocked = e.target.value;
        this.setState({ stock_notification });
    }

    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                <h1 className="text-center">Stocker Notifications</h1>
                <Modal isOpen={this.state.showModal}>
                    <ModalHeader className="w-100">
                        <Row>
                            <Col className="d-flex align-items-center">Stock Notification</Col>
                            <Col md="auto d-flex align-items-center">
                                {this.state.stock_notification ? this.stock_notifications_service.getStockStatusIcon(this.state.stock_notification) : ""}
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={3}>SKU</Label>
                                <Col sm={9}>
                                    <Input value={this.state.stock_notification ? this.state.stock_notification.product_sku : ""} disabled />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Name</Label>
                                <Col sm={9}>
                                    <Input value={this.state.stock_notification ? this.state.stock_notification.product_name : ""} disabled />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Warehouse Code</Label>
                                <Col sm={9}>
                                    <Input value={this.state.stock_notification ? this.state.stock_notification.product_formatted_warehouse_code : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Available Qty</Label>
                                <Col sm={3}>
                                    <Input value={this.state.stock_notification ? this.state.stock_notification.product_quantity : ""} disabled className="h-100" />
                                </Col>
                                <Label sm={3}>Order Qty</Label>
                                <Col sm={3}>
                                    <Input value={this.state.stock_notification ? this.state.stock_notification.order_item_qty : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Quantity</Label>
                                <Col sm={9}>
                                    <Input
                                        onChange={this.setQuantity}
                                        value={this.state.stock_notification ? this.state.stock_notification.quantity_stocked : ""}
                                        placeholder="Quantity Stocked"
                                        className="h-100"
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Container fluid>
                            <Row>
                                <Col className="p-0">
                                    <Button onClick={() => {this.pauseStockNotication()}}>
                                        Close
                                    </Button>
                                </Col>
                                <Col className="p-0 text-right">
                                    <Button
                                        className={this.state.stock_notification ? this.state.stock_notification.stock_status != 'pending' ? "btn-custom-light" : "d-none" : "d-none"}
                                        onClick={() => {this.updateStockNotification("pending", this.state.stock_notification)}}
                                    >
                                        Pending
                                    </Button>
                                    {' '}
                                    <Button
                                        className={this.state.stock_notification ? this.state.stock_notification.stock_status == 'pending' ? "btn-custom-pending" : "d-none" : "d-none"}
                                        onClick={() => {this.updateStockNotification("ready", this.state.stock_notification)}}
                                    >
                                        Ready
                                    </Button>
                                    {' '}
                                    <Button
                                        className={this.state.stock_notification ? this.state.stock_notification.stock_status != 'pending' ? "btn-custom-secondary" : "d-none" : "d-none"}
                                        onClick={() => {this.updateStockNotification("restocked", this.state.stock_notification)}}
                                        disabled={this.state.stock_notification ? this.state.stock_notification.stock_status == "restocked" ? true : false : false}
                                    >
                                        Restocked
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </Modal>
                <Container fluid>
                    <StockNotificationsPagination
                        page_size_key={"_stockNotificationPageSize"}
                        url={process.env.REACT_APP_API_URL + '/api/v2/stock_notifications'}
                        data_attr={"stock_notifications"}
                        showStockNotication={this.showStockNotication}
                        refresh={this.state.refresh}
                        resetRefresh={this.resetRefresh}
                        title="Stock Requests"
                    />
                </Container>
            </div>
        )
    }
}
export default StockNotifications;

/*
getAction = (stock_notification) => {
    if(stock_notification.started_stocking_at && stock_notification.stock_status != 'pending'){
        return "Restock"
    }else if(stock_notification.stock_status != "pending"){
        return "Pending"
    }else{
        return "Ready"
    }
}

<Button
    color="primary"
    onClick={() => {this.updateStockNotification(this.state.stock_notification)}}
    disabled={this.state.stock_notification ? this.state.stock_notification.stock_status == "restocked" ? true : false : false}
>
    {this.state.stock_notification ? this.getAction(this.state.stock_notification) : ""}
</Button>

if (stock_notification.stock_status != "pending"){
    body = {
        offset_started_at: moment().format('YYYY-MM-DD hh:mm:ss'),
        stock_status: "pending"
    }
} else if (stock_notification.stock_status == 'pending'){
    body = {
        offset_ended_at: moment().format('YYYY-MM-DD hh:mm:ss'),
        stock_status: "needs_restock"
    }
}

if (!stock_notification.started_stocking_at) {
    body.started_stocking_at = moment().format('YYYY-MM-DD hh:mm:ss');
}else if(stock_notification.started_stocking_at && stock_notification.stock_status != 'pending'){
    body.stocked_at = moment().format('YYYY-MM-DD hh:mm:ss');
}
*/