import React from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEllipsisH, faCheck, faExclamation, faCircle } from '@fortawesome/free-solid-svg-icons'

export default class OrdersService {

    constructor(){

    }

    updateOrder = (order_id, body) => {
        let token = JSON.parse(localStorage.getItem("token"));

        console.log(body);
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v2/orders/' + order_id, {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    updateOrderItem = (order_id, order_item_id,body) => {
        let token = JSON.parse(localStorage.getItem("token"));

        console.log(body);
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v2/orders/' + order_id + "/order_items/" + order_item_id, {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getClassStatus = (order_item) => {
        if(order_item.stock_status == "restocked"){
            return "restocked"
        }else if(order_item.stock_status == "needs_restock"){
            return "needs_restock"
        }else if(!!order_item.packed_at){
            return "packed"
        }else{
            return ""
        }
    }
}