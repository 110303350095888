import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import moment from 'moment';

import {
    Table, Button,
    Form, FormGroup, Label,
    Input, Modal, ModalHeader, ModalBody, Spinner,
} from 'reactstrap';

import StockNotificationsService from "../Services/StockNotificationsService";

class StockNotificationsPagination extends Component {
    state = {
        isLoading: false,
        data: [],
        page_count: 0,
        page_size: 10,
        page: 0,
    }

    constructor(props){
        super(props);

        this.stock_notication_service = new StockNotificationsService();

        this.URL = this.props.url;
        this.DATA_ATTR = this.props.data_attr

        this.SHOW_STOCK_NOTIFICATION = this.props.showStockNotication;

        this.PAGE_SIZE_KEY = this.props.page_size_key;
        let page_size = localStorage.getItem(this.PAGE_SIZE_KEY) || 10;
        this.state.page_size = page_size;
    }

    componentDidMount(){
        this.getList(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.refresh == false && this.props.refresh){
            this.getList(this.state.page == 0 ? 1 : this.state.page);
        }
    }

    getList = (new_page) => {
        this.setState({
            isLoading: true
        }, async () => {
            let token = JSON.parse(localStorage.getItem("token"));
            var result = await fetch(this.URL + '?page=' + new_page + '&page_size=' + this.state.page_size, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                //console.log(data);
                return data;
            })
            .catch((error) => {
                return error;
            });
            // console.log(result);
            if(result["meta"]){
                this.setState({
                    isLoading: false,
                    data: result[this.DATA_ATTR] ?  result[this.DATA_ATTR] : [],
                    page_count: result["meta"]['page_count'],
                    page: new_page - 1
                }, () => {
                    this.props.resetRefresh(false);
                });
            }
        })
    }

    handlePageClick = (data) => {
        console.log(data);
        this.getList(data.selected + 1);
    }

    handleChange = (e) => {
        localStorage.setItem(this.PAGE_SIZE_KEY, e.target.value);
        this.setState({
            page_size: e.target.value
        }, () => {
            this.getList(1);
        })
    }

    getIndex = (index) => {
        if(this.state.page == 0){
            return (index + 1);
        }else{
            return (this.state.page*this.state.page_size) + (index + 1);
        }
    }

    /*
    getStockStatusClass = (stock_notification) => {
        var stockClass = "order-item"
        if (!!stock_notification.stocked_at) {
            return stockClass + " stocked";
        } else if (stock_notification.stock_status == "pending") {
            return stockClass + " pending";
        } else if (!!stock_notification.started_stocking_at) {
            return stockClass + " started";
        } else {
            return stockClass;
        }
    }
    */

    render() {
        return (
            <div>
                <h3>{ this.props.title || 'Stock Notifications' }</h3>
                <Modal isOpen={this.state.isLoading}>
                    <ModalBody className="text-center">
                        <div className="mb-2">Loading...</div>
                        <Spinner>
                            Loading...
                        </Spinner>
                    </ModalBody>
                </Modal>
                {this.state.data.length > 0 &&
                    <div>
                        <Table>
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Name</th>
                                    <th>Available Qty</th>
                                    <th>Order Qty</th>
                                    <th>Created</th>
                                    <th>Worker</th>
                                    <th>Warehouse Code</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((item, index) => {
                                        return <tr key={item.id} onClick={() => {this.SHOW_STOCK_NOTIFICATION(item)}}>
                                            <td>{item.product_sku}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.product_quantity}</td>
                                            <td>{item.order_item_qty}</td>
                                            <td>{moment(item.created_at).local().format('MMM Do YYYY, hh:mm:ss a')}</td>
                                            <td>{item.worker_pack}</td>
                                            <td>{item.product_formatted_warehouse_code}</td>
                                            <td>{this.stock_notication_service.getStockStatusIcon(item)}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-between">
                            { this.state.page_count > 1 && (
                                <div>
                                    <div style={{padding: '4px 0px'}}>
                                        <Form>
                                            <FormGroup className="form-inline form-short">
                                                <Label className="mr-1">Shows: </Label>
                                                <Input type="select" name="select" id="exampleSelect" onChange={this.handleChange} value={this.state.page_size}>
                                                    <option>10</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </Input>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                    <ReactPaginate
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.page_count}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }

}
export default StockNotificationsPagination;