import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import OrdersService from "../Services/OrdersService";
import StockNotificationsService from "../Services/StockNotificationsService";

import OrdersPagination from "./OrdersPagination";

import {
    Row, Col, Table,
    Container, Collapse,
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'

class Orders extends Component {
    state = {
        showModal: false,
        showOrderItemModal: false,
        showCollapse: true,
        refresh: false,
        order: null,
        order_item: null
    }

    constructor(props){
        super(props);

        this.orders_service = new OrdersService();
        this.stock_notications_service = new StockNotificationsService();
    }

    componentDidMount = () => {

    }

    requestRefill = async (order, order_item) => {
        let stock_notication_rsp = await this.updateStockNotification("request_refill", order, order_item);
        let order_item_rsp = await this.updateOrderItem("request_refill", order, order_item);

        console.log(stock_notication_rsp);
        console.log(order_item_rsp);

        if(order_item_rsp.error){
            toast.error("Something went wrong. Please try again.");
            return;
        }

        let index = order.order_items.findIndex(a => a.id == order_item_rsp.id);
        if(index > -1){
            let new_order = JSON.parse(JSON.stringify(order));
            new_order.order_items[index] = order_item_rsp;

            toast.success('Order Item successfully updated.');
            this.setState({
                order: new_order,
                order_item: order_item_rsp,
                showOrderItemModal: false
            })
        }
    }

    pauseOrder = async () => {
        let order = this.state.order;
        let order_rsp = await this.updateOrder("pause", order);

        // console.log(order_rsp);
        if(order_rsp.error){
            toast.error("Something went wrong. Please try again.");
            return;
        }

        let new_order = JSON.parse(JSON.stringify(order));
        // toast.success('Order  successfully updated.');
        this.setState({
            order: new_order
        })

        this.toggleModal();

    }

    startPackingOrderItem = async (order_item) => {
        let order = this.state.order;
        if (!order_item.started_packing_at) {
            let order_item_rsp = await this.updateOrderItem("start_packing", order, order_item);

            console.log(order_item_rsp);
            if(order_item_rsp.error){
                toast.error("Something went wrong. Please try again.");
                return;
            }

            let index = order.order_items.findIndex(a => a.id == order_item_rsp.id);
            if(index > -1){
                let new_order = JSON.parse(JSON.stringify(order));
                new_order.order_items[index] = order_item_rsp;
                // toast.success('Order Item successfully updated.');
                this.setState({
                    order: new_order,
                    order_item: order_item_rsp
                })
                if (order.status === 'approved') {
                    this.updateOrder('start_packing', order);
                }
            }
        }
        this.toggleOrderItemModal(order_item);

    }

    pack = async (order, order_item) => {
        let order_item_rsp = await this.updateOrderItem("packed", order, order_item);

        console.log(order_item_rsp);
        if(order_item_rsp.error){
            toast.error("Something went wrong. Please try again.");
            return;
        }

        let index = order.order_items.findIndex(a => a.id == order_item_rsp.id);
        if(index > -1){
            let new_order = JSON.parse(JSON.stringify(order));
            new_order.order_items[index] = order_item_rsp;

            toast.success('Order Item successfully updated.');
            this.setState({
                order: new_order,
                order_item: order_item_rsp,
                showOrderItemModal: false
            })
        }
    }

    updateStockNotification = (type, order, order_item) => {
        console.log("Order: ", order);
        console.log("OLD: ", order_item);
        var body = {};

        switch(type){
            case "request_refill":
                body = {
                    stock_notification: {
                        order_item_id: order_item.id,
                        stock_status: "needs_restock"
                    }
                }
                break;
        }

        return this.stock_notications_service.craeteStockNotification(body).then((result) => {
            console.log("NEW: ", result);
            return result;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    updateOrderItem = (type, order, order_item) => {
        console.log("Order: ", order);
        console.log("OLD: ", order_item);
        var body = {};

        switch(type){
            case "request_refill":
                body = {
                    order_item: { stock_status: "needs_restock" }
                }
                break;
            case "start_packing":
                body = {
                    order_item: { started_packing_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "packed":
                body = {
                    order_item: { packed_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
        }

        return this.orders_service.updateOrderItem(order.id, order_item.id, body).then((result) => {
            console.log("NEW: ", result);
            return result;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    updateOrder = (type, order) => {
        console.log("Order: ", order);
        var body = {};

        switch(type){
            case "start_packing":
                body = {
                    order: { started_packing_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "packed":
                body = {
                    order: { packed_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "pause":
                body = {
                    order: { offset_started_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
            case "unpause":
                body = {
                    order: { offset_ended_at: moment().format('YYYY-MM-DD hh:mm:ss') }
                }
                break;
        }

        return this.orders_service.updateOrder(order.id, body).then((result) => {
            console.log("NEW: ", result);
            return result;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    }

    showOrder = (new_order) => {
        this.setState({
            showModal: true,
            order: new_order
        })
        if (new_order.offset_started_at) {
            this.updateOrder('unpause', new_order);
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            // showCollapse: !this.state.showModal == false ? false : this.state.showCollapse
        })
    }

    toggleOrderItemModal = (new_order_item) => {
        this.setState({
            order_item: new_order_item,
            showOrderItemModal: !this.state.showOrderItemModal
        })
    }

    toggleCollapse = () => {
        this.setState({
            showCollapse: !this.state.showCollapse
        })
    }

    resetRefresh = (new_refresh) => {
        this.setState({
            refresh: new_refresh
        })
    }

    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />
                <h1 className="text-center">Worker Orders</h1>
                <Modal isOpen={this.state.showOrderItemModal}>
                    <ModalHeader className="w-100">
                        <Row>
                            <Col className="d-flex align-items-center">Order Item</Col>
                            <Col md="auto d-flex align-items-center">
                                {this.state.order_item && this.state.order_item.packed_at &&
                                    <span className="fa-stack fa-1x">
                                        <FontAwesomeIcon icon={faCircle} className="fa-stack-2x color-secondary" key={1} />
                                        <FontAwesomeIcon icon={faCheck} className="fa-stack-1x color-light" key={2}/>
                                    </span>
                                }
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            {this.state.order_item && this.state.order_item.product_photos.length > 0 &&
                                <Row>
                                    <img className="m-auto pb-4" alt="Order status icon" style={{maxHeight: "150px"}}
                                        src={process.env.REACT_APP_API_URL + this.state.order_item.product_photos[0].url}
                                    />
                                </Row>
                            }
                            <FormGroup row>
                                <Label sm={3}>Shelf Code</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order_item ? this.state.order_item.shelf_code : ""} disabled />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Quantity</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order_item ? this.state.order_item.qty : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Name</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order_item ? this.state.order_item.name : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Werehouse Code</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order_item ? this.state.order_item.product_formatted_warehouse_code : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Container fluid>
                            <Row>
                                <Col className="p-0">
                                    <Button onClick={() => {this.toggleOrderItemModal(null)}}>
                                        Close
                                    </Button>
                                </Col>
                                <Col className="p-0 text-right">
                                    <Button color="primary"
                                        className={this.state.order_item ? this.state.order_item.stock_status != 'needs_restock' ? "btn-primary" : "d-none" : "d-none"}
                                        onClick={() => {this.requestRefill(this.state.order, this.state.order_item)}}
                                    >
                                        Request Refill
                                    </Button>
                                    {' '}
                                    <Button
                                        className={this.state.order_item ? this.state.order_item.stock_status == 'needs_restock' ? "btn-custom-danger" : "d-none" : "d-none"}
                                    >
                                        Awaiting Refill
                                    </Button>
                                    {' '}
                                    <Button className="btn-custom-secondary"
                                        onClick={() => {this.pack(this.state.order, this.state.order_item)}}
                                        disabled={this.state.order_item ? !this.state.order_item.packed_at ? false : true : false}
                                    >
                                        Packed
                                        </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showModal} size={this.state.showCollapse ? "lg" : ""}>
                    <ModalHeader className="w-100">
                        <Row>
                            <Col className="d-flex align-items-center">Order{this.state.order ? ": " + this.state.order.order_number : ""}</Col>
                            <Col md="auto d-flex align-items-center">
                                {this.state.order ? this.state.order.status : ""}
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={3}>Order Date</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order ? moment(this.state.order.order_date).local().format('MMM Do YYYY, hh:mm:ss a') : ""} disabled />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Total Items</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order ? this.state.order.total_items : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Total Item Line</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order ? this.state.order.total_item_lines : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Labor Time</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order ? this.state.order.labor_time : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Shipping Type</Label>
                                <Col sm={9}>
                                    <Input value={this.state.order ? this.state.order.shipping_type : ""} disabled className="h-100" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Order Items</Label>
                                {/* <Col sm={9}>
                                    <Button onClick={() => {this.toggleCollapse()}} block>{this.state.showCollapse ? "Hide" : "View"}</Button>
                                </Col> */}
                            </FormGroup>
                            <Container fluid className="p-0">
                                <Collapse isOpen={this.state.showCollapse}>
                                    <Row>
                                        <Col>
                                            <Table style={{backgroundColor: "WhiteSmoke"}}>
                                                <thead>
                                                    <tr>
                                                        <th>Shelf Code</th>
                                                        <th>Quantity</th>
                                                        <th>Name</th>
                                                        <th>Werehouse Code</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.order && this.state.order.order_items.map((item, index) => {
                                                            return <tr key={item.id} className={this.orders_service.getClassStatus(item)} onClick={() => {this.startPackingOrderItem(item)}}>
                                                                <td>{item.shelf_code}</td>
                                                                <td>{item.qty}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.product_formatted_warehouse_code}</td>
                                                                <td>
                                                                    {item.packed_at &&
                                                                        <span className="fa-stack fa-1x">
                                                                            <FontAwesomeIcon icon={faCircle} className="fa-stack-2x color-secondary" key={1} />
                                                                            <FontAwesomeIcon icon={faCheck} className="fa-stack-1x color-light" key={2}/>
                                                                        </span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Container>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Container fluid>
                            <Row>
                                <Col className="p-0 text-right">
                                    <Button onClick={() => {this.pauseOrder()}}>
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </Modal>
                <Container fluid>
                    <OrdersPagination
                        page_size_key={"_orderPageSize"}
                        url={process.env.REACT_APP_API_URL + '/api/v2/orders'}
                        data_attr={"orders"}
                        showOrder={this.showOrder}
                        refresh={this.state.refresh}
                        resetRefresh={this.resetRefresh}
                        title="Active Orders"
                    />
                </Container>
                <Container fluid>
                    <OrdersPagination
                        page_size_key={"_completedOrderPageSize"}
                        url={process.env.REACT_APP_API_URL + '/api/v2/orders?completed=true'}
                        data_attr={"orders"}
                        showOrder={this.showOrder}
                        refresh={this.state.refresh}
                        resetRefresh={this.resetRefresh}
                        title="Completed Orders"
                    />
                </Container>
            </div>
        )
    }
}
export default Orders;
