export default class OAuthService {

    constructor(){

    }

    getToken = new Promise((resolve, reject) => {
        var body_params = new URLSearchParams();
        body_params.append("client_id", process.env.REACT_APP_CLIENT_ID);
        body_params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
        body_params.append("response_type", "token");
        body_params.append("grant_type", "client_credentials");

        fetch(process.env.REACT_APP_API_URL + '/oauth/token.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body_params.toString()
        })
        .then(response => {
            if(!response.ok){
                reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
            }else{
                resolve(response.json());
            }
        })
        .catch((error) => {
            reject(error);
        });
    });

    getUserToken = (username, password) => {
        return new Promise((resolve, reject) => {
            var body_params = new URLSearchParams();
            body_params.append("client_id", process.env.REACT_APP_CLIENT_ID);
            body_params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
            body_params.append("response_type", "token");
            body_params.append("grant_type", "password");
            body_params.append("username", username);
            body_params.append("password", password);
            body_params.append("owner_type", "user");

            fetch(process.env.REACT_APP_API_URL + '/oauth/token.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body_params.toString()
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getUserInfo = () => {
        let token = JSON.parse(localStorage.getItem("token"));
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v2/users/me', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}