import React, { Component } from "react";
import { createBrowserHistory } from 'history';

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import OAuthService from '../Services/OAuthService';

import {
    Row, Col,
    Container,
    Form, FormGroup,
    Input,
    Button,
    Alert,
    Toast, ToastHeader, ToastBody
} from 'reactstrap';

class Login extends Component {
    state = {
        userName: "",
        password: "",
        alertMsg: "",
    }

    constructor(props){
        super(props);
        this.oas = new OAuthService();
    }

    postLogin = (e) => {
        e.preventDefault();

        this.oas.getUserToken(this.state.userName, this.state.password).then((result) => {
            //console.log(result);
            localStorage.setItem('token', JSON.stringify(result["access_token"]));
            //this.props.setAuthToken(result["access_token"]);

            const history = createBrowserHistory();
            history.push({ pathname: '/dashboard' })
            window.location.reload(false);

        }).catch((error) => {
            console.log(error);
            if (error["error"]["status"] === 500) {
                // alert(error["error"]["statusText"])
                this.setState({ alertMsg:"Something went wrong. It's possible  there is no account registered with the email submitted."});
            }
            error["resp"].then(result => {
                if(result["error_description"]){
                    // alert(errors);
                    this.setState({ alertMsg: "Incorrect email address or password."})
                } else {
                    this.setState({ alertMsg:"Something went wrong. Please try again."});
                }
            })
        });
    }

    setUserName = (e) => {
        this.setState({ userName: e.target.value });
    }

    setPassword = (e) => {
        this.setState({ password: e.target.value });
    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Row className="m-4">
                        <Col>
                            <Toast className="m-auto">
                                <ToastHeader>
                                    Login
                                </ToastHeader>
                                <ToastBody>
                                    <Alert color="danger" className="small-box mb-3 text-center" isOpen={this.state.alertMsg && !this.state.responseMsg ? true : false } >
                                        <div className="text-medium">
                                            { this.state.alertMsg }
                                        </div>
                                    </Alert>
                                    <Form className="login-form m-auto" onSubmit={this.postLogin} >
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                value={this.state.userName}
                                                onChange={this.setUserName}
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-0">
                                            <Input
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.setPassword}
                                                placeholder="Password"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button type="submit" color="primary"
                                                disabled={(this.state.userName.trim().length == 0 || this.state.password.trim().length == 0) ? true : false}
                                                style={{width: '100%', marginTop: '10px', fontSize: '1em', padding: '2px'}}
                                            >
                                                Login
                                            </Button>
                                        </FormGroup>
                                    </Form>
                                </ToastBody>
                            </Toast>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default Login;
