import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faCheck, faExclamation, faCircle } from '@fortawesome/free-solid-svg-icons'

export default class StockNotificationsService {

    constructor(){

    }

    craeteStockNotification = (body) => {
        let token = JSON.parse(localStorage.getItem("token"));
        
        console.log(body);
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v2/stock_notifications/', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    updateStockNotification = (id, body) => {
        let token = JSON.parse(localStorage.getItem("token"));

        console.log(body);
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/api/v2/stock_notifications/' + id, {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                if(!response.ok){
                    reject({error: {status: response.status, statusText: response.statusText}, resp: response.json()});
                }else{
                    resolve(response.json());
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    getStockStatusIcon = (stock_notification) => {
        let status_icon;
        if (!!stock_notification.stocked_at) {
            status_icon = [
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x color-secondary" key={1} />,
                <FontAwesomeIcon icon={faCheck} className="fa-stack-1x color-light" key={2}/>
            ]
        } else if (stock_notification.stock_status == 'pending') {
            status_icon = [
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x color-pending" key={1} />,
                <FontAwesomeIcon icon={faEllipsisH} className="fa-stack-1x color-light" key={2}/>
            ]
        } else if (!!stock_notification.started_stocking_at) {
            status_icon = [
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x color-danger" key={1}/>,
                <FontAwesomeIcon icon={faExclamation} className="fa-stack-1x color-light" key={2}/>
            ]
        } else {
            status_icon = null;
        }

        if(status_icon){
            return React.createElement('span', {className: 'fa-stack fa-1x'}, status_icon);
        }else{
            return "";
        }
    }   
}