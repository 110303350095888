import React from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import { createBrowserHistory } from 'history';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { useAuth } from "./Auth/auth";

import Restricted from "./Layout/Restricted";
import NotFound from "./Layout/NotFound";
import Login from "./Auth/Login";
import Dashboard from "./Dashboard/Dashboard";

const Router = () => {
    const { token } = useAuth();
    if(window.location.pathname === "/"){
        const history = createBrowserHistory();
        history.push({ pathname: token ? '/dashboard' : "/login" })
    }

    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/login" component={Login} title='Login' />
                <PrivateRoute path="/dashboard" component={Dashboard} title='Dashboard' />
                <PrivateRoute path="/restricted" component={Restricted} title='Retricted Page' />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
};

export default Router;